@import "dark.css";
@import "light.css";
@import "text.css";
@import "forms.css";
@import "buttons.css";
/* The code below ensures that all elements are sized in this more intuitive way. 
Many browsers already use box-sizing: border-box; for many form elements (but not all - which is why inputs and text areas look different at width: 100%;). */
*,
::after,
::before {
  box-sizing: border-box;
  cursor: url("../images/pokeball.png"), auto !important;
}

html {
  font-size: 100%;
  scroll-behavior: smooth;
  background-color: black;
  cursor: url("../images/pokeball.png"), auto;
}

body {
  background: var(--backgroundColor);
  font-family: var(--bodyFont);
  font-weight: 400;
  line-height: 1.75;
  color: var(--textColor);
  cursor: url("../images/pokeball.png") 0 0, auto !important;
}

a,
button {
  line-height: 1.15;
}

button:disabled {
  cursor: not-allowed;
}

ul {
  list-style-type: none;
  padding: 0;
}

.img {
  width: 100%;
  display: block;
  object-fit: cover;
}

/* alerts */
.alert {
  padding: 0.375rem 0.75rem;
  margin-bottom: 1rem;
  border-color: transparent;
  border-radius: var(--borderRadius);
  text-align: center;
  letter-spacing: var(--letterSpacing);
  color: var(--textColor);
}

.alert-danger {
  color: var(--red-dark);
  background: var(--red-light);
}

.alert-success {
  color: var(--primary-500);
  background: var(--green-50);
}

.container {
  width: var(--fluid-width);
  max-width: var(--max-width);
  margin: 0 auto;
}

.full-page {
  min-height: 100vh;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

* {
  transition: "all 0.3s ease-in-out";
}

